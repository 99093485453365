import React from 'react'

function DevopsSuite() {
    return (
        <div className='bg-[#BFE5DE] py-20 rounded-lg mb-10'>
            <div className='flex justify-center items-center'>
                <p className='text-[#0B7077] text-center bg-[#F5F5F5] py-2 px-5 border- rounded-lg mb-5'>Product Suite</p>
            </div>
            <div className='flex flex-col items-center justify-center mb-4'>
                <h1 className='text-center md:text-[50px] text-[45px] md:max-w-2xl md:font-[500] font-[400]'>Our Devops as a Service Suite</h1>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-20'>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon11.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-10'>
                        <h2 className='text-lg font-semibold'>Continuous Integration/Continuous Deployment (CI/CD)</h2>
                        <p className='mt-2 text-gray-600'>We automate the process of building, testing, and deploying software to make it faster, more reliable, and less prone to errors.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon12.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Infrastructure as Code (IaC)</h2>
                        <p className='mt-2 text-gray-600'>By using code, we manage infrastructure tasks more efficiently, leading to less complexity and greater time savings.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon13.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Monitoring and Logging</h2>
                        <p className='mt-2 text-gray-600'>We monitor the performance of websites and log all activities to ensure everything runs smoothly and to quickly resolve any issues.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon14.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Containerization and Orchestration</h2>
                        <p className='mt-2 text-gray-600'>Deploy and scale apps effortlessly with Docker and Kubernetes—Docker ensures consistency, while Kubernetes automates scaling and management.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon15.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Collaboration and Communication</h2>
                        <p className='mt-2 text-gray-600'>Boost team productivity with our integrated collaboration tools, designed for seamless communication and efficient workflow.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon16.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Cloud Services Management</h2>
                        <p className='mt-2 text-gray-600'>Manage resources across multiple cloud platforms with ease, ensuring efficiency, security, and optimized performance</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon17.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Testing and Quality Assurance</h2>
                        <p className='mt-2 text-gray-600'>Automated testing tools that ensure high-quality software with minimal effort, quickly identifying bugs and performance issues for faster, reliable delivery.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                    <div className='absolute top-2'>
                        <img src={require('../images/icon18.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Security and Compliance</h2>
                        <p className='mt-2 text-gray-600'>DevSecOps integrates security throughout the DevOps process, ensuring compliance from development to deployment.</p>
                    </div>
                </div>
                <div className='border rounded-lg p-4 relative bg-[#E9F6F4]'>
                   <div className='absolute top-2'>
                        <img src={require('../images/icon19.png')} alt="DevOps Detail" className="" />
                    </div>
                    <div className='mt-12'>
                        <h2 className='text-lg font-semibold'>Training and Support</h2>
                        <p className='mt-2 text-gray-600'>Access in-depth training to master our service, with continuous support available whenever you need assistance</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DevopsSuite