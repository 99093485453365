import { div } from 'framer-motion/client'
import React from 'react'

function WhyUs() {
return (
    <div className='text-center my-20'>
        <div className='flex justify-center items-center'>
            <p className='text-[#0B7077] text-center bg-[#F5F5F5] py-2 px-5 border- rounded-lg'>Why Us</p>
        </div>
        <h2 className='md:font-[700] md:text-[50px] text-[32px] md:w-full font-[700]'>Your success, our mission</h2>
        <p className='md:text-[18px] text-[14px] text-[#3F3F4F]'>We provide the tools and support you need to achieve your goals.</p>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mx-20'>
            <div className='border p-5 rounded-lg text-left'>
                <img src={require('../images/devops1.png')} alt='Card 1' className='w-full h-52 object-cover rounded-lg' />
                <h3 className='text-[24px] font-[600] mt-4'>Expert Team</h3>
                <p className='text-[14px] text-[#3F3F4F]'>Lorem ipsum dolor sit amet consectetur. Cursus ac tellus sed nisi quis egestas vitae in eget.</p>
            </div>
            <div className='border p-5 rounded-lg text-left'>
                <img src={require('../images/devops2.png')} alt='Card 2' className='w-full h-52 object-cover rounded-lg' />
                <h3 className='text-[24px] font-[600] mt-4'>Tailored Solutions</h3>
                <p className='text-[14px] text-[#3F3F4F]'>Lorem ipsum dolor sit amet consectetur. Cursus ac tellus sed nisi quis egestas vitae in eget.</p>
            </div>
            <div className='border p-5 rounded-lg text-left'>
                <img src={require('../images/devops3.png')} alt='Card 3' className='w-full h-52 object-cover rounded-lg' />
                <h3 className='text-[24px] font-[600] mt-4'>End-to-End Services</h3>
                <p className='text-[14px] text-[#3F3F4F]'>Lorem ipsum dolor sit amet consectetur. Cursus ac tellus sed nisi quis egestas vitae in eget..</p>
            </div>
        </div>
    </div>
)
}

export default WhyUs